import React from 'react';
import { graphql } from 'gatsby';
import Select from 'react-select';
import * as R from 'ramda';
import moment from 'moment';
import qs from 'qs';
import Layout from '../components/Layout';
import PropertiesRoll from '../components/PropertiesRoll';
import PropertyCollections from '../components/PropertyCollections'
import {
  moneyFormat,
  propertyGuestOptions,
} from '../utils';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export class PropertiesPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  componentDidMount() {
    moment.locale('en');
    this.setState({ filters: this.props.queryFilters });
  }

  setFilter(filter, type) {
    if (!!filter) {
      return this.setState({
        filters: { ...this.state.filters, [type]: filter.value },
      });
    }
    return this.setState({ filters: R.dissoc(type, this.state.filters) });
  }

  render() {
    const { properties, bookingData, searchObject, filterObject } = this.props;

    const propertySearchObject = {
      location: searchObject.location,
      checkIn: searchObject.checkIn,
      checkOut: searchObject.checkOut,
      guests: searchObject.guests,
      placeholder: searchObject.placeholder,
      findingProperties: searchObject.findingProperties,
      noMatch: searchObject.noMatch,
      options: [
        { label: searchObject.options.anywhere, value: 'Anywhere' },
        { label: searchObject.options.niseko, value: 'Niseko' },
        { label: searchObject.options.furano, value: 'Furano' },
      ],
      serviceTypeLabel: searchObject.serviceTypeOptions.serviceTypeLabel,
      serviceTypeOptions: [
        { label: searchObject.serviceTypeOptions.hakuLife, value: 'HakuLife', info: searchObject.serviceTypeOptions.hakuLifeInformation },
        { label: searchObject.serviceTypeOptions.luxury, value: 'Luxury', info: searchObject.serviceTypeOptions.luxuryInformation },
        { label: searchObject.serviceTypeOptions.signature, value: 'Signature', info: searchObject.serviceTypeOptions.signatureInformation }
      ],
    }

    const propertyPriceOptions = {
      placeholder: filterObject.propertyPriceOptions.propertyPriceLabel,
      options: [
        { label: filterObject.propertyPriceOptions.highest, value: 'highest' },
        { label: filterObject.propertyPriceOptions.lowest, value: 'lowest' },
      ],
    };

    const propertyTypeOptions = {
      placeholder: filterObject.propertyTypeOptions.propertyTypeLabel,
      options: [
        { label: filterObject.propertyTypeOptions.home, value: 'Home' },
        { label: filterObject.propertyTypeOptions.condo, value: 'Condo' },
      ],
    };
    
    const propertyList = () => {
      if (R.isEmpty(this.state.filters)) {
        return properties;
      }
      const activeFilterKeys = R.keys(this.state.filters);
      const activeFilters = R.map((key) => {
        switch (key) {
          case 'type':
            return R.pathEq(
              ['node', 'frontmatter', 'type'],
              this.state.filters.type
            );
          case 'guests':
            return R.pathSatisfies(
              (item) => parseInt(item) >= this.state.filters.guests,
              ['node', 'frontmatter', 'maxGuests']
            );
          case 'location':
            if (this.state.filters.location === 'Anywhere') {
              return R.pathSatisfies((item) => true, [
                'node',
                'frontmatter',
                'location',
              ]);
            }
            return R.pathSatisfies(
              (item) => R.includes(this.state.filters.location, item),
              ['node', 'frontmatter', 'tags']
            );
          case 'price':
            return R.pathSatisfies((item) => true, [
              'node',
              'frontmatter',
              'startingPrice',
            ]);
          case 'serviceType':
            return R.pathEq(
              ['node', 'frontmatter', 'serviceType'],
              this.state.filters.serviceType
            );
          default:
            return true;
        }
      }, activeFilterKeys);

      const filteredProperties = R.filter(R.allPass(activeFilters), properties);
      
      if (!!this.state.filters.price) {
        const sortedLowest = filteredProperties.sort(
          (a, b) =>
            parseFloat(a.node.frontmatter.startingPrice.replace(/,/g, '')) -
            parseFloat(b.node.frontmatter.startingPrice.replace(/,/g, ''))
        );
        const sortedHighest = R.reverse(sortedLowest);
        return this.state.filters.price === 'highest'
          ? sortedHighest
          : sortedLowest;
      }
      return filteredProperties;
    };
    const getDefaultFilterValues = (filter) => {
      if (!!this.props.queryFilters && !!this.props.queryFilters[filter]) {
        return {
          label: this.props.queryFilters[filter],
          value: this.props.queryFilters[filter],
        };
      }
      return null;
    };
    return (
      <div className='section page-container container content properties-page-container'>
        {this.props.helmet || ''}
        <PropertyCollections title={ this.props.propertiesTitle } subtitle={ this.props.propertiesSubtitle } collectionTiles={ this.props.collectionTiles } />
        {/* <BackgroundImage imageInfo={ heroImageObject.image } classes="page-header-image">
          <h1 className="title page-title">
            <hr />
            { title }
          </h1>
          <ImageOverlay overlay={ heroImageObject.overlay } />
        </BackgroundImage> */}
        {!bookingData ? null : (
          <h2 className='available-properties-title'>{`${
            propertyList().length
          } properties from ${moment(bookingData.checkIn).format(
            'DD MMMM, YYYY'
          )} to ${moment(bookingData.checkOut).format('DD MMMM, YYYY')}`}</h2>
        )}
        <div className='property-filters'>
          <div className='columns'>
            <div className='column is-one-fifth filter-column'>
              <Select
                options={propertySearchObject.serviceTypeOptions}
                isSearchable={false}
                defaultValue={getDefaultFilterValues('serviceType')}
                isClearable
                onChange={(e) => this.setFilter(e, 'serviceType')}
                placeholder={propertySearchObject.serviceTypeLabel}
              />
            </div>
            <div className='column is-one-fifth filter-column'>
              <Select
                options={propertyGuestOptions}
                isSearchable={false}
                defaultValue={getDefaultFilterValues('guests')}
                isClearable
                onChange={(e) => this.setFilter(e, 'guests')}
                placeholder='Number of guests'
              />
            </div>
            <div className='column is-one-fifth filter-column'>
              <Select
                options={propertyTypeOptions.options}
                isSearchable={false}
                defaultValue={getDefaultFilterValues('type')}
                isClearable
                onChange={(e) => this.setFilter(e, 'type')}
                placeholder={propertyTypeOptions.placeholder}
              />
            </div>
            <div className='column is-one-fifth filter-column'>
              <Select
                options={propertySearchObject.options}
                isSearchable={false}
                defaultValue={getDefaultFilterValues('location')}
                isClearable
                onChange={(e) => this.setFilter(e, 'location')}
                placeholder={propertySearchObject.placeholder}
              />
            </div>
            <div className='column is-one-fifth filter-column'>
              <Select
                options={propertyPriceOptions.options}
                isSearchable={false}
                defaultValue={getDefaultFilterValues('price')}
                isClearable
                onChange={(e) => this.setFilter(e, 'price')}
                placeholder={propertyPriceOptions.placeholder}
              />
            </div>
          </div>
        </div>
        <PropertiesRoll
          properties={propertyList()}
          propertySearch={propertySearchObject}
          bookingData={bookingData}
          icons={this.props.icons}
          propertyObject='property_data_en'
        />
      </div>
    );
  }
}

const PropertiesPage = ({ data, location }) => {
  let propertiesList = data.allMarkdownRemark.edges;
  let bookingData = null;
  const { title, siteUrl } = useSiteMetadata();
  // filters all properties for hotelIds that have been provided by the RoomBoss API after an availability search
  // otherwise it returns all uploaded properties
  if (
    !!location.state &&
    !!location.state.propertiesResponse &&
    !!location.state.propertiesResponse.availableHotels
  ) {
    bookingData = {
      checkIn: location.state.propertiesResponse.checkIn,
      checkOut: location.state.propertiesResponse.checkOut,
      guests: location.state.propertiesResponse.numberGuests,
    };
    const filteredForAvailability = R.filter(
      (item) => item.availableRoomTypes.length > 0,
      location.state.propertiesResponse.availableHotels
    );
    const propertiesArray = filteredForAvailability.map(
      (hotel) => hotel.hotelId
    );
    const filteredPropertiesList = data.allMarkdownRemark.edges.filter(
      (property) => {
        return propertiesArray.includes(property.node.frontmatter.hotelId);
      }
    );
    const stayLength = moment(location.state.propertiesResponse.checkOut).diff(
      moment(location.state.propertiesResponse.checkIn),
      'days'
    );
    // next we check if there is pricing information returned by the RoomBoss API and amend the properties to reflect that
    const hotelIdsAndPricingArray = location.state.propertiesResponse.availableHotels.map(
      (hotel) => {
        if (hotel.availableRoomTypes.length) {
          return {
            hotelId: hotel.hotelId,
            priceData: (
              hotel.availableRoomTypes[0].priceRetail / stayLength
            ).toFixed(0),
          };
        }
        return { hotelId: hotel.hotelId };
      }
    );
    propertiesList = filteredPropertiesList.map((property) => {
      const pricingInformation = R.find(
        R.propEq('hotelId', property.node.frontmatter.hotelId)
      )(hotelIdsAndPricingArray);

      let adjustedPricing = !!pricingInformation.priceData
        ? moneyFormat(pricingInformation.priceData)
        : property.node.frontmatter.startingPrice;

      // make exception for Kira Kira penthouse as it shares an RB id with Kira Kira Suites which is significantly cheaper
      if (property.node.frontmatter.title === 'Kira Kira Penthouse') {
        const hotel = R.find(R.propEq('hotelName', 'Kira Kira'))(
          location.state.propertiesResponse.availableHotels
        );
        const room = R.find(
          R.propEq('roomTypeId', '2c98902a57f37ae70157f5280eb128b2')
        )(hotel.availableRoomTypes);
        adjustedPricing =
          !!hotel && !!room
            ? moneyFormat((room.priceRetail / stayLength).toFixed(0))
            : property.node.frontmatter.startingPrice;
      }

      if (adjustedPricing === NaN || adjustedPricing === "NaN") {
        adjustedPricing = property.node.frontmatter.startingPrice;
      }
      return R.set(
        R.lensPath(['node', 'frontmatter', 'startingPrice']),
        adjustedPricing,
        property
      );
    });
  }
  // Sort properties based on order set in hotel ids settings
  propertiesList.sort((a, b) => {
    // find index of valueA in hotelIds.frontmatter.properties.property.hotelId - index of b.node.frontmatter.title in hotelids
    const valueA = R.path(['node', 'frontmatter', 'hotelId'], a);
    const valueB = R.path(['node', 'frontmatter', 'hotelId'], b);
    const indexA = R.findIndex(R.pathEq(['property', 'hotelId'], valueA))(
      data.hotelIds.frontmatter.properties
    );
    const indexB = R.findIndex(R.pathEq(['property', 'hotelId'], valueB))(
      data.hotelIds.frontmatter.properties
    );
    return indexA - indexB;
  });

  const queryFilters = R.omit(
    ['fbclid'],
    qs.parse(location.search, { ignoreQueryPrefix: true })
  );
  const pageData = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <PropertiesPageTemplate
        propertiesTitle={ pageData.propertiesTitle }
        propertiesSubtitle={ pageData.propertiesSubtitle }
        location={location}
        heroImageObject={pageData.heroImageObject}
        title={pageData.propertiesTitle}
        properties={propertiesList}
        searchObject={ pageData.propertySearchTranslations }
        filterObject={ pageData.propertyFilterTranslations }
        collectionTiles={ pageData.collectionTiles }
        bookingData={bookingData}
        queryFilters={queryFilters}
        icons={pageData.icons}
        helmet={
          <MetaComponent
            title={`${pageData.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${pageData.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${pageData.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default PropertiesPage;

export const pageQuery = graphql`
  query PropertiesPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "accommodation-page" } }) {
      frontmatter {
        propertiesTitle
        propertiesSubtitle
        icons {
          signature
          luxury
          hakuLife
          allNiseko
        }
        seoObject {
          metaTitle
          metaDescription
        }
        heroImageObject {
          overlay
          image
        }
        collectionTiles {
          title
          text
          icon
        }
        propertyFilterTranslations {
          propertyTypeOptions {
            propertyTypeLabel
            home
            condo
          }
          propertyPriceOptions {
            propertyPriceLabel
            highest
            lowest
          }
        }
        propertySearchTranslations {
          noMatch
          checkOut
          findingProperties
          placeholder
          location
          guests
          checkIn
          luxury
          options {
            anywhere
            niseko
            furano
          }
          serviceTypeOptions {
            serviceTypeLabel
            all
            hakuLife
            luxury
            signature
            hakuLifeInformation
            luxuryInformation
            signatureInformation
          }
        }
      }
    }
    hotelIds: markdownRemark(
      frontmatter: { templateKey: { eq: "rb-properties" } }
    ) {
      frontmatter {
        properties {
          property {
            hotelId
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "property-listing" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            hotelId
            startingPrice
            type
            tags
            maxGuests
            serviceType
            allNiseko
            awards {
              image
            }
            externalPropertyLink
            featuredImage
            property_data_en {
              title
              bathrooms
              bedrooms
              short_description
              guests
              location
            }
          }
        }
      }
    }
  }
`;
